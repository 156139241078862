import request from "./request";

// 获取客户列表数据
export function getClientList(query) {
  return request.get('/blade-customer/customer/list', query)
};

// 获取商品管理数据
export function getItemInfo(query) {
  return request.get('/xilianmao-item/itemInfo/list', query)
};

// 批量上架商品
export function groundingGoods(query) {
  return request.post(`/xilianmao-item/itemInfo/submit-item-listing-status-batch-up?ids=${query}`)
};

// 批量下架商品
export function soldOutGoods(query) {
  return request.post(`/xilianmao-item/itemInfo/submit-item-listing-status-batch-down?ids=${query}`)
};

// 批量删除商品 
export function removeGoods(query) {
  return request.post(`/xilianmao-item/itemInfo/remove?ids=${query}`)
};

// 更新商品
export function editGoods(query) {
  return request.post(`/xilianmao-item/itemInfo/submit`,query)
};

// 设置营业时间
export function saveBusinessHours(query) {
  return request.post(`/xilianmao-store/store/save-business-hours`,query)
};

// 获取商品详情信息
export function getGoodsDetails(query) {
  return request.get('/xilianmao-item/itemInfo/detail', query)
};

// 获取店铺管理数据
export function getShopInfo(query) {
  return request.get('/xilianmao-store/store/list', query)
};

// 获取省市区数据
export function getAreaInfo(query) {
  return request.get('/xilianmao-store/store/region-tree', query)
};

// 获取大区地址
export function getAreaList(query) {
  return request.get('/xilianmao-store/store/store-tree', query)
};

// 获取有赞商品列表
export function getYzGoodsList(query) {
  return request.get('/xilianmao-item/itemInfo/list-yz-items', query)
};

// 设置店铺状态
export function setShopStatus(query) {
  return request.post(`/xilianmao-store/store/save-shop-status`,query)
};

// 获取订单管理数据
export function getOrderInfo(query) {
  return request.get('/xilianmao-order/orderInfo/list', query)
};

// 获取订单详情数据
export function getOrderDetails(query) {
  return request.get('/xilianmao-order/orderInfo/detailByOrderNo', query)
};

// 订单退款
export function orderRefundInfo(query) {
  return request.post(`/xilianmao-order/orderRefundInfo/submit-voluntary-refund`,query)
};

// 提交审核
export function bathSunbmit(query) {
  return request.post(`/xilianmao-item/itemInfo/submit-audit?id=${query}`)
};

// 批量提交审核
export function bathSunbmits(query) {
  return request.post(`/xilianmao-item/itemInfo/batch/submit-audit?ids=${query}`)
};
// 获取售后订单数据
export function getAfterSaleList(query) {
  return request.get('/xilianmao-order/orderRefundInfo/list', query)
};

// 获取售后订单详情
export function getAfterSaleInfo(query) {
  return request.get('/xilianmao-order/orderRefundInfo/detailByRefundNo', query)
};

// 售后订单拒绝退款
export function rejectRefund(query) {
  return request.post(`/xilianmao-order/orderRefundInfo/submit-refuse-refund`,query)
};

// 售后订单同意退款
export function agreeRefund(query) {
  return request.post(`/xilianmao-order/orderRefundInfo/submit-agree-refund`,query)
};

// 通过核销码获取订单数据
export function getVerifyOrderInfo(query) {
  return request.get('/xilianmao-order/orderInfo/detailByVerifyCode', query)
};

// 确认核销订单
export function confirmVerify(query) {
  return request.post(`/xilianmao-order/orderInfo/submit-verify-status-confirm?verifyCode=${query.verifyCode}`)
};

// 取消核销订单
export function cancelVerify(query) {
  return request.post(`/xilianmao-order/orderInfo/submit-verify-status-cancel?verifyCode=${query.verifyCode}`)
};

// 上传文件
export function uploadFile(query) {
  return request.post(`/blade-resource/oss/endpoint/put-file-attach`,query)
};

// 导入商品
export function importGoods(query) {
  return request.post(`/xilianmao-item/itemInfo/sync-item-into-db`,query)
};

// 获取售后订单详情
export function getBindShopIds(query) {
  return request.get(`/xilianmao-item/itemInfo/list-item-listing-shop-ids?id=${query}`)
};


// 获取单个商品绑定店铺数据
export function getBindShopId(query) {
  return request.get(`/xilianmao-item/itemInfo/detail-shops`,query)
};

// 获取核销权限列表
export function getUserPerm(query) {
  return request.get(`/xilianmao-item/user/perm/list`,query)
};

// 新增或编辑核销权限
export function submitUserPerm(query) {
  return request.post(`/xilianmao-item/user/perm/submit`,query)
};

// 删除核销权限
export function removeUserPerm(query) {
  return request.post(`/xilianmao-item/user/perm/remove`,query)
};

// 导入核销权限
export function importUserPerm(query) {
  return request.post(`/xilianmao-item/user/perm/import-verity-user-perm`,query)
};

// 登陆
export function authLogin(query) {
  return request.post(`/xilianmao-auth/login?account=${query.account}&password=${query.password}`)
};

// 订单关闭时间
export function getRetuendTime() {
  return request.get(`/xilianmao-order/client/orderInfo/queryOrderPayExpireSeconds`)
};

// 查询有赞店铺
export function getYzShop(query) {
  return request.get(`/xilianmao-store/store/select-yz-store-rel`,query)
};

// 修改店铺映射
export function upDateShop(query) {
  return request.post(`/xilianmao-store/store/update-yz-store-rel`,query)
};

// 查询核销管理列表
export function getVerityList(query) {
  return request.get(`/xilianmao-verity/user/perm/list`,query)
};

// 新增或者编辑核销权限
export function submitUserVerity(query) {
  return request.post(`/xilianmao-verity/user/perm/submit`,query)
};

// 删除权益
export function removeVerity(query) {
  return request.post(`/xilianmao-verity/user/perm/remove?ids=${query}`);
};

// 导入核销模板
export function importVerity(query) {
  return request.post(`/xilianmao-verity/user/perm/import-verity-user-perm`,query);
};

// 查询核销指定店铺
export function getVerityShop(query) {
  return request.get(`/xilianmao-verity/user/perm/detailShopInfos?mobile=${query}`);
};

// 根据订单号查询售后详情
export function getDetailByOrderNo(query) {
  return request.get(`/xilianmao-order/orderRefundInfo/detailByOrderNo`,query);
};

// 查询预约列表
export function getServerList(query) {
  return request.get(`/xilianmao-reservation/server/list`,query);
};

// 取消订单预约
export function getCancelOrder(query) {
  return request.get(`/xilianmao-reservation/server/cancelOrder`,query);
};

// 预约订单详情
export function getQueryOrderDetail(query) {
  return request.get(`/xilianmao-reservation/server/detail`,query);
};