import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/login',
    name: 'login',
    meta:{
      title:'登陆',
    },
    component: () => import('../pages/login.vue')
  },
  {
    path: '/',
    name: 'clientManagement',
    meta:{
      title:'客户管理',
    },
    component: () => import('../pages/clientManagement.vue')
  },
  {
    path: '/goodsManagement',
    name: 'goodsManagement',
    meta:{
      title:'商品管理',
    },
    component: () => import('../pages/goodsManagement.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta:{
      title:'订单管理',
    },
    component: () => import('../pages/order.vue'),
    children:[
      {
        path: '/order/orderManagement',
        name: 'orderManagement',
        meta:{
          title:'订单管理',
        },
        component: () => import('../pages/orderManagement.vue'),
      },
      {
        path: '/order/afterSale',
        name: 'afterSale',
        meta:{
          title:'售后维权',
        },
        component: () => import('../pages/afterSale.vue'),
      },
      
    ]
  },
  {
    path: '/shopManagement',
    name: 'shopManagement',
    meta:{
      title:'店铺管理',
    },
    component: () => import('../pages/shopManagement.vue')
  },
  {
    path: '/verificationManage',
    name: 'verificationManage',
    meta:{
      title:'核销权限管理',
    },
    component: () => import('../pages/verificationManage.vue')
  },
  {
    path: '/predetermineManagement',
    name: 'predetermineManagement',
    meta:{
      title:'预约管理',
    },
    component: () => import('../pages/predetermineManagement.vue')
  },
];
const router = new Router({
  base: process.env.BASE_URL,
  // 配置URL和组价直接的映射关系
  routes,
  // history模式 
  mode: 'hash'
})
// 将router对象传入到vue实例中
export default router
