<template>
  <div class="dialog">
    <el-input :value="shopVal" @focus="shopDialog = true" @input="handleInput" placeholder="请选择店铺"></el-input>
    <el-dialog v-model="shopDialog" title="店铺选择">
      <div class="dialog-left">
        <el-tree :data="treeDate" :props="defaultProps" node-key="id" show-checkbox />
      </div>
      <div class="dialog-right">
        <el-table :data="gridData">
          <el-table-column property="date" label="店铺名称" width="150" />
        </el-table>
        <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.current"
            :limit.sync="queryParams.size"
            @pagination="getList"
          />      
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getShopInfo, getAreaInfo} from '../../api/index';
export default {
  props: {
    value:{
      type: String,
      default: ''
    }
  },
  data () {
    return {
      defaultProps:{
        children: 'children',
        label: 'label',
        disabled: 'disabled',
      },
      shopDialog: false,
      treeDate:[],
      gridData:[],
      queryParams:{
        province:'',
        city:'',
        district:'',
        current: 1,
        size: 10,
      },
      total: 0,
    }
  },
  mounted () {
    getAreaInfo()
    .then((res) => {
      console.log(res);
      if ( res.code == 200) {
        this.treeDate = res.data;  
      }
    })
    .catch(err => console.log(err));
  },
  methods: {
    getList(){
      getShopInfo(this.queryParams)
      .then((res) => {
        console.log(res);
        if (res.code == 200){
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      })
      .catch(err => console.log(err));
    },
    handleInput(e){
   	 // 这句代码是关键
      this.$emit('input',e.target.value) 
    }
  }
}
</script>

<style>

</style>