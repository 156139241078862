<template>
  <div class="header_wrapper">
    <el-card shadow="never"> {{name}} </el-card>
  </div>
</template>

<script >
export default {
  name:'headers',
  data () {
    return {
      name: '',
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.name = route.meta.title;
      },
      immediate: true,
    },
  },
  
}
</script>

<style scoped>
.header_wrapper{
  position: fixed;
  top: 0;
  left: 130px;
  width: 100%;
  z-index: 20;
}
.header_wrapper :deep() .el-card {
  border-radius: none;
}
.header_wrapper :deep() .el-card__body {
  padding: 10px;
}
</style>