import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router/index.js'
import Pagination from './components/Pagination/index.vue'
import ShopInput  from './components/ShopDialog/index.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.component('Pagination', Pagination);
Vue.component('ShopInput', ShopInput);
// 滚动加载更多
Vue.directive('loadMore', {
  bind(el, binding) {
    // 获取element，定义scroll
    let select_dom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    select_dom.addEventListener('scroll', function () {
      let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (height) {
        binding.value()
      }
    })
  }
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
