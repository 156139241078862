<template>
  <div class="menu_container">
    <div class="logo">
      <div class="logo-img">
        <img src="https://img01.yzcdn.cn/upload_files/2023/06/07/FmT9EyYM2R5PVGo1DnF9J1H0jBb6.png" alt="">
      </div>
    </div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
      background-color="#304156"
      text-color="#fff"
      active-text-color="#ffd04b"
      mode="vertical"
    >
    <div v-for="item in menuData" :key="item.name">
      <el-submenu v-if="item.children" :index="item.path">
        <template slot="title">{{item.meta.title}}</template>
        <el-menu-item v-for="items in item.children" :key="items.name" :index="items.path">
          <template slot="title">{{items.meta.title}}</template>
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else  :index="item.path">
        <template slot="title">{{item.meta.title}}</template>
      </el-menu-item>
    </div>
    
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'Meau',
  data() {
    return {
      activeName: '/',
    };
  },

  created() {
    console.log(this.isShowMeau, 'isShowMeau');
    console.log(this.$router.options.routes);
    let arr = [];
    this.$router.options.routes.forEach(res => {
      if (res.meta.title != '登陆') {
        arr.push(res);
      }
    });
    this.menuData = arr;
    console.log(this.menuData);
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
        this.$router.push({path:key})
    }
  },
  watch: {
    isShowMeau(n, o) {
      this.isShow = n;
    }
  }
};
</script>

<style lang="scss" scoped>
.menu_container {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 130px;
  background-color: #304156;
  .logo{
    .logo-img{
      margin:20px auto;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      background: #fbed6f;
      img{
        width: 60px;
        height: 60px;
      }
    }
  }
  .ivu-menu-submenu {
    background-color: #304156;
    color: #f3f3f3;
  }
  .ivu-menu {
    background-color: #26374c;
  }
  .ivu-menu-light.ivu-menu-vertical
    .ivu-menu-item-active:not(.ivu-menu-submenu) {
    background-color: #26374c;
    color: #f3f3f3;
  }
  .ivu-menu-vertical .ivu-menu-item {
    color: #fff;
  }

  .el-menu{
    border-right:0;
  }
  .el-submenu .el-menu-item {
    min-width: auto;
  }
}
</style>
