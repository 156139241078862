<template>
    <div id="app">
        <Headers v-if="!isLogin"/>
        <div v-if="!isLogin" class="main_container">
          <router-view  />
        </div>
        <Menu v-if="!isLogin"/>
      <router-view v-if="isLogin"  />
    </div>
</template>

<script>

import Menu from './components/Menu/index.vue';
import Headers from './components/Header/index.vue';
export default {
  name: 'App',
  components: {
    Menu,Headers
  },
  data () {
    return {
      isLogin: false
    }
  },
  watch:{
    $route(to){
      console.log(to);
      if (!localStorage.getItem("XLMtoken")) {
        this.$router.push({name:'login'});
      }
      if (to.name == 'login') {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    }
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.main_container {
  margin-top: 50px;
  margin-left: 130px;
  height: calc(100% - 100px);
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
</style>
